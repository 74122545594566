<template>
  <el-form
    class="auditModel"
    :model="auditForm"
    :rules="rules"
    size="small"
    label-width="130px"
  >
    <el-form-item label="审核结果">
      <el-radio-group v-model="auditForm.pass">
        <el-radio :label="1">通过</el-radio>
        <el-radio :label="2">不通过</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="审核意见">
      <el-input
        type="textarea"
        :autosize="{ minRows: 6, maxRows: 6 }"
        show-word-limit
        maxlength="100"
        v-model="auditForm.reviewComment"
        suffix-icon="el-icon-date"
        autocomplete="off"
        placeholder="请输入审核意见"
      ></el-input>
    </el-form-item>
    <el-form-item label="文件上传">
      <UploadFile
        type="other"
        style="margin-left: 10px"
        @success="uploadSuccess"
        v-model="auditForm.accessPath"
      ></UploadFile>
      <div class="ml10" v-show="auditForm.fileName">
        <span>{{ auditForm.fileName }}</span>
        <i class="el-icon-close cursor ml10" @click="handleRemoveFile()"></i>
      </div>
    </el-form-item>
    <div class="disfr jc ac">
      <el-button size="small" type="primary" @click="submitAudit"
        >提交</el-button
      >
    </div>
  </el-form>
</template>

<script>
import UploadFile from "@/components/UploadFile.vue";
import storage from "@/common/utils/storage";
import { startAudit } from "@/common/js/api";
export default {
  components: { UploadFile },
  props: {
    checkData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      auditForm: {
        beforeStatus: null,
        idList: [],
        pass: 1,
        reviewComment: "",
        accessPath: "",
        fileId: "",
        fileName: "",
      },
      rules: {},
      disabled: false,
    };
  },
  watch: {},
  methods: {
    uploadSuccess(val) {
      this.auditForm.accessPath = val.accessPath;
      this.auditForm.fileId = val.fileId;
      this.auditForm.fileName = val.fileName;
    },
    handleRemoveFile() {
      this.auditForm.accessPath = "";
      this.auditForm.fileId = "";
      this.auditForm.fileName = "";
    },
    async submitAudit() {
      let params = JSON.parse(JSON.stringify(this.auditForm));
      params.beforeStatus = this.checkData.reviewStatus;
      params.idList = [this.checkData.id];
      params.resolveUserW3Id = storage.getUserInfo().userId;
      let res = await startAudit(params);
      if (res && res.code === 0) {
        this.$message.success("审核成功");
        this.$emit("submitAudit");
      }
    },
  },
};
</script>

<style lang="scss">
.auditModel {
  width: 50%;
  margin: 0 auto;
  .el-input,
  .el-textarea {
    display: inline-block;
    width: 300px;
  }
  .formItemRemark {
    font-size: 12px;
    // width:700px;
  }
  .el-input--prefix .el-input__inner {
    padding-left: 12px;
    padding-right: 12px;
  }
  .el-date-editor .el-input__prefix {
    left: auto;
    right: 5px;
  }
}
</style>
