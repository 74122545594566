<template>
  <div class="box">
    <el-row :gutter="10">
      <el-col :span="7">
        <el-select
          style="width: 100%"
          clearable
          filterable
          size="small"
          placeholder="审核状态"
          v-model="searchData.reviewStatus"
        >
          <el-option
            v-for="item in startingAduitList"
            :key="item.key"
            :value="item.key"
            :label="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="10">
        <el-input
          style="width: 100%"
          size="small"
          v-model="searchData.keyword"
          class="input-with-select"
          placeholder="请输入搜索内容"
        >
          <el-select
            size="small"
            slot="prepend"
            v-model="searchData.chooseType"
            placeholder="请选择类型"
          >
            <el-option label="首发ID" :value="1"></el-option>
            <!-- <el-option label="APPID" :value="2"></el-option> -->
            <el-option label="应用名称" :value="3"></el-option>
            <el-option label="开发者名称" :value="4"></el-option>
            <el-option label="审核员" :value="5"></el-option>
          </el-select>
        </el-input>
      </el-col>
    </el-row>
    <el-row type="flex" align="middle" :gutter="10">
      <el-col :span="12">
        <el-row type="flex" align="middle">
          <span style="white-space: nowrap"> 提交日期：</span>
          <el-date-picker
            size="small"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            v-model="searchData.submitDate"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker> </el-row
      ></el-col>
      <el-col>
        <el-button size="small" type="primary" @click="startIngList"
          >搜索</el-button
        >
      </el-col>
    </el-row>
    <PageTable
      :columns="columns"
      :tableData="tableData"
      @page-change="handleChangePage"
      @selection-change="handleSelectionChange"
    >
      <!-- <el-table-column
        slot="prefix"
        type="selection"
        :selectable="selectableRow"
        width="55"
      >
      </el-table-column> -->
      <template slot-scope="{ row }" slot="operation">
        <div>
          <span
            v-has="'lookinfo'"
            class="colorGreen pointer mr10"
            @click="handleOperate('lookinfo', row)"
            >查看</span
          >
          <el-button
            type="text"
            v-has="'downloadAPK'"
            @click="handleOperate('download', row)"
            :disabled="!row.testApkUrl"
            >评测apk包</el-button
          >
          <el-button
            type="text"
            v-has="'review'"
            class="colorRed pointer"
            :disabled="!reviewList.includes(row.reviewStatus)"
            @click="handleOperate('review', row)"
            >审核</el-button
          >
        </div>
      </template>
      <template slot-scope="{ row }" slot="reviewStatus">
        <span>{{ row.reviewStatus | typeDesc(startingAduitList) }}</span>
      </template>
    </PageTable>
    <!-- <div class="page-foot" v-has="'reviewBatch'">
      <el-select
        v-model="batchOperation"
        size="small"
        class="mr10"
        placeholder="批量操作"
      >
        <el-option :value="1" label="审核通过"></el-option>
        <el-option :value="2" label="审核不通过"></el-option>
      </el-select>
      <el-button size="small" type="primary" @click="handleBatchOperation"
        >提交操作</el-button
      >
    </div> -->
    <el-dialog title="首发申请" :visible.sync="dialogFormVisible" center>
      <viewDetail
        :startingInfo="startingInfo"
        :propReleaseArea="releaseArea"
      ></viewDetail>
    </el-dialog>
    <el-dialog
      center
      title="首发审核"
      destroy-on-close
      :visible.sync="dialogAuditVisible"
    >
      <auditModel
        :checkData="checkData"
        @submitAudit="submitAudit"
      ></auditModel>
    </el-dialog>
  </div>
</template>

<script>
import {
  startAudit,
  startIngList,
  getLanguageList,
  lookstartingInfo,
} from "@/common/js/api";
import { startingAduitList } from "@/common/constant/constant.js";
import { recordUrl } from "@/common/js/commonApi.js";
import PageTable from "@/components/PageTable.vue";
import viewDetail from "./viewDetail.vue";
import auditModel from "./auditModel.vue";
import storage from "@/common/utils/storage";
import moment from "moment";
export default {
  name: "starting",
  components: {
    PageTable,
    viewDetail,
    auditModel,
  },
  data() {
    let end = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    let start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
    start = moment(start).format("YYYY-MM-DD HH:mm:ss");
    return {
      languageList: [],
      searchData: {
        languageId: "",
        submitDate: [start, end],
        reviewStatus: "",
        chooseType: 3,
        keyword: "",
        count: true,
      },
      columns: [
        {
          key: "id",
          label: "首发ID",
          align: "center",
          width: 120,
          "show-overflow-tooltip": true,
        },
        {
          key: "appId",
          label: "APPID",
          align: "center",
          width: 120,
          "show-overflow-tooltip": true,
        },
        {
          key: "title",
          label: "应用名称",
          align: "center",
          width: 120,
          "show-overflow-tooltip": true,
        },
        {
          key: "packageName",
          label: "应用包名",
          align: "center",
          width: 120,
          "show-overflow-tooltip": true,
        },
        {
          key: "name",
          label: "开发者名称",
          align: "center",
          width: 140,
          "show-overflow-tooltip": true,
        },
        {
          key: "createTime",
          label: "提交时间",
          align: "center",
          width: 140,
          "show-overflow-tooltip": true,
        },
        {
          key: "reviewCreateTime",
          label: "审核时间",
          align: "center",
          width: 140,
          "show-overflow-tooltip": true,
        },
        {
          key: "releaseCountryCount",
          label: "发布国家/地区",
          width: "150px",
          align: "center",
          "show-overflow-tooltip": true,
        },
        {
          key: "reviewName",
          label: "审核员",
          align: "center",
          "show-overflow-tooltip": true,
        },
        {
          slot: "reviewStatus",
          label: "审核状态",
          align: "center",
          width: 140,
          "show-overflow-tooltip": true,
        },
        {
          slot: "operation",
          label: "操作",
          align: "center",
          fixed: "right",
          width: 150,
          "show-overflow-tooltip": true,
        },
      ],
      tableData: {
        page: 1,
        size: 10,
        total: 0,
        list: [],
      },
      startingAduitList,
      dialogFormVisible: false, //查看
      dialogAuditVisible: false, //审核
      batchOperation: 1,
      selectionData: [],
      checkData: {},
      startingInfo: {},
      reviewList: [800], // 可审核的状态列表reviewList
      releaseArea: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    selectableRow(row) {
      if (!this.reviewList.includes(row.reviewStatus)) return false;
      return true;
    },
    submitAudit() {
      this.dialogAuditVisible = false;
      this.init();
    },
    init() {
      this.getLanguageList();
      this.startIngList();
    },
    handleChangePage({ page, size }) {
      this.startIngList({ page, size });
    },
    handleSelectionChange(selection) {
      this.selectionData = selection;
    },
    async startIngList(params = {}) {
      let reqParsms = {
        page: 1,
        size: 10,
        ...this.searchData,
        ...params,
      };
      reqParsms.startTime = reqParsms.submitDate ? reqParsms.submitDate[0] : "";
      reqParsms.endTime = reqParsms.submitDate ? reqParsms.submitDate[1] : "";
      delete reqParsms.submitDate;
      const res = await startIngList(reqParsms);
      res.data.list.forEach((item) => {
        item.releaseCountryCount = item.releaseCountryCount + "个";
      });
      this.tableData = res.data;
    },
    async getLanguageList() {
      const res = await getLanguageList();
      this.languageList = res.data;
    },
    handleOperate(operationType, row) {
      this.checkData = row;
      if (operationType == "lookinfo") {
        this.lookstartingInfo(row);
      } else if (operationType == "review") {
        if (!this.reviewList.includes(row.reviewStatus)) return;
        this.dialogAuditVisible = true;
      } else if (operationType === "download") {
        this.recordUrl(row.testApkUrl);
      }
    },
    async lookstartingInfo(row) {
      let params = {
        prioritizedApplyId: row.prioritizedApplyId,
      };
      const res = await lookstartingInfo(params);
      if (res && res.code === 0) {
        this.releaseArea = res.data.country.split("|").map((item) => {
          return Number(item);
        });
        this.startingInfo = res.data;
        this.dialogFormVisible = true;
      }
    },
    async handleBatchOperation() {
      let idList = this.selectionData.filter((item) => {
        return this.reviewList.includes(item.reviewStatus);
      });
      idList = idList.map((item) => item.id);
      let params = {
        idList,
        pass: this.batchOperation,
        resolveUserW3Id: storage.getUserInfo().userId,
      };
      let res = await startAudit(params);
      if (res.code === 0) {
        this.$message.success("审核成功");
        this.init();
      }
    },
    // 记录下载行为
    async recordUrl(downUrl) {
      const res = await recordUrl({ downUrl });
      if (res && res.code === 0) {
        window.open(downUrl);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.input-with-select {
  /deep/.el-input-group__prepend {
    background: #fff;
    width: 80px;
  }
}
</style>
